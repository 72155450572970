import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PageNotFound from "./components/error/notFound/pageNotFound";
// import Top from "./components/body/top";
import Header from "./components/header/Header";
// import Question from "./components/body/question";
// import Confirm from "./components/body/confirm";
// import Thanks from "./components/body/thank";
import store from "./store";
import {Provider} from "react-redux";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import AnswerResult from "./components/body/answerResult";
import Advice from "./components/body/advice";

export default function App() {
    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <Router>
                    <Header/>
                    <Routes>
                        {/*<Route path="/top/:uri" element={<Top />} />*/}
                        {/*<Route path="/question/:uri" element={<Question />} />*/}
                        {/*<Route path="/confirm/:uri" element={<Confirm />} />*/}
                        {/*<Route path="/thanks/:uri" element={<Thanks />} />*/}
                        <Route path="/answer-result" element={<AnswerResult />} />
                        <Route path="/advice" element={<Advice />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    <footer><img src="/assets/images/ac-logo.svg" alt="AirCampus"/></footer>
                </Router>
            </Provider>
        </I18nextProvider>

    );
}
