import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './loading.css';

const Loading: React.FC = () => {
    return (
        <div className="loading-overlay">
            <div className="loading">
                <FontAwesomeIcon icon={faSpinner} spin />
            </div>
        </div>
    );
};

export default Loading;
