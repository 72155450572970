import React from 'react';
import ViewFileComponent from "../form/view/viewfile";
import {useTranslation} from "react-i18next";

interface AnswerComponentProps {
    data?: answer
}

interface answer {
    question_id: string,
    //title
    answer_type: number
    text?: string,
    choice?: string[],
    selected_other: boolean,
    file?: string,
    uri?: string,
    ref_id?: string
}

const AnswerComponent: React.FC<AnswerComponentProps> = (props: AnswerComponentProps) => {
    const { t } = useTranslation();
    function renderChoiceAnswer(answerType: number) {
        switch (answerType) {
            case 50:
            case 40:
                if(props.data?.selected_other || props.data?.choice){
                    if(props.data?.selected_other){
                        return <><span style={{fontWeight: "bold"}}>{t('other')}</span><p key={`${props.data?.question_id}`} className="ac-check">{props.data?.text}</p></>
                    }
                    return <p key={props.data?.question_id} className="ac-check">{props.data?.choice}</p>
                }else{
                    return <span>{t("unanswer-text")}</span>
                }
            case 70:
                return props.data?.file ? <ViewFileComponent fileName={props.data?.file}
                                          filePath={props.data?.uri}
                                          id={props.data?.ref_id}
                                          attachViewType={-1}/> : <span>{t("unanswer-text")}</span>
            case 60:
                let i = 1;
                if((props.data?.choice?.length || 0) > 0 || props.data?.selected_other){
                    const choices = props.data?.choice?.map((item) => {
                        i++;
                        return <p key={`${props.data?.question_id}${i}`} className="ac-check">{item}</p>;
                    });
                    if (props.data?.selected_other) {
                        i++;
                        choices?.push(
                            <>
                                <span style={{fontWeight: "bold"}}>{t('other')}</span>
                                <p key={`${props.data?.question_id}${i}`} className="ac-check">{props.data?.text}</p>
                            </>
                        );
                    }
                    return choices;
                }else{
                    return <span>{t("unanswer-text")}</span>;
                }
            case 45:
                return props.data?.choice ? <p key={props.data?.question_id} className="ac-check">{props.data?.choice}</p> : <span>{t("unanswer-text")}</span>
            default:
                return props.data?.text ? <p key={props.data?.question_id}>{props.data?.text}</p> : <span>{t("unanswer-text")}</span>
        }
    };
    return <>{renderChoiceAnswer(props.data?.answer_type || 0)}</>;
};

export default AnswerComponent;