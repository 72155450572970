import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface EnqueteSliceState {
    banner: string,
    approved: boolean,
    id: number,
    uri: string,
    start: boolean,
    answerId: number,
    title: string
};

const initialState: EnqueteSliceState = {
    banner: "",
    approved: false,
    id: 0,
    uri: "",
    start: false,
    answerId: 0,
    title: "AirCampus Form"
};

export const enqueteSlice = createSlice({
    name: "enquete",
    initialState,
    reducers: {
        setBanner: (state, action: PayloadAction<string>) => {
            state.banner = action.payload;
        },
        setApproved: (state, action: PayloadAction<boolean>) => {
            state.approved = action.payload;
        },
        setId: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        setUri: (state, action: PayloadAction<string>) => {
            state.uri = action.payload;
        },
        setStart: (state, action: PayloadAction<boolean>) => {
            state.start = action.payload;
        },
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
    }
});
export const {setBanner, setApproved, setId, setUri, setStart, setTitle} = enqueteSlice.actions;
export default enqueteSlice.reducer;