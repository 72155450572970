interface ViewFileComponentProps {
    type?: number,
    fileName?: string,
    filePath?: string,
    id?: string,
    attachViewType?: number
}

const ViewFileComponent: React.FC<ViewFileComponentProps> = (props: ViewFileComponentProps) => {
    const fileExtension = props.filePath?.split(".").pop()?.toLowerCase();
    if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'gif') {
        if (props.attachViewType === 0 || props.attachViewType === -1) {
            return <img src={props.filePath} alt="" style={{ maxWidth: '100%', minWidth: 'auto' }} />;
        } else if (props.attachViewType === 1) {
            return <a href={props.filePath} target="_blank" rel="noreferrer">{props.fileName}</a>;
        } else {
            return <></>;
        }
    } else if (fileExtension === 'mp3' || fileExtension === 'wav' || fileExtension === 'aac' || fileExtension === 'gif') {
        if (props.attachViewType === 0 || props.attachViewType === -1) {
            return <div className="audio-container" style={{ maxWidth: "100%" }}>
                <audio controls preload="auto">
                    <source type="audio/mp3" src={props.filePath} />
                </audio>
            </div>;
        } else if (props.attachViewType === 1) {
            return <a href={props.filePath} target="_blank" rel="noreferrer">{props.fileName}</a>;
        } else {
            return <></>;
        }
    } else if (fileExtension === 'mp4' || fileExtension === 'webm' || fileExtension === 'mov') {
        if (props.attachViewType === 0 || props.attachViewType === -1) {
            return <div className="video-container video-container-16-9" style={{ maxWidth: '100%', minWidth: '200px', maxHeight: 'auto' }}>
                <video width="100%" height="100%" controls preload="auto">
                    <source src={props.filePath} />
                </video>
            </div>;
        } else if (props.attachViewType === 1) {
            return <a href={props.filePath} target="_blank" rel="noreferrer">{props.fileName}</a>;
        } else {
            return <></>;
        }
    } else if(fileExtension === 'avi'){
        return <a href={props.filePath} target="_blank" rel="noreferrer">{props.fileName}</a>;
    } else{
        return <a href={props.filePath} target="_blank" rel="noreferrer">{props.fileName}</a>;
    }
};

export default ViewFileComponent;