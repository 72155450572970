export const getFromLocalStorage = (key: string) => {
    const value = localStorage.getItem(key);
    // Parse the value if it exists
    return value ? JSON.parse(value) : null;
}

// Save a value to localStorage
export const saveToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}