import React from 'react';
import { useTranslation } from 'react-i18next';

interface AnswerTextComponentProps {
    title: string,
    questionNo: number,
    text: string,
    required: boolean
}

const AnswerTextComponent: React.FC<AnswerTextComponentProps> = (props: AnswerTextComponentProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="acf-sect">
                <h4 className="acf-sect-header">
                    <p className="acf-sect-header-ttl">Q{props.questionNo}</p>
                    <div className="acf-sect-header-ques">
                        <p><span dangerouslySetInnerHTML={{ __html: props.title || '' }} /> {props.required ? <span className="acf-sect-header-ques-req" style={{width: "auto"}}>{t('required')}</span> : <></>}</p></div>
                </h4>
                <p className="acf-score-txt">{props.text}</p>
            </div>
        </>
    );
};

export default AnswerTextComponent;