import React, {useEffect} from "react";
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios";
import {RootState} from "../../store";
import {getCookieByName} from "../../utils/CookieUtil";
import {setAvatar, setUserName, setAuthen, setLoginId} from "../../services/authentication/authenticationSlice";
import { useTranslation } from 'react-i18next';
import { saveToLocalStorage } from "../../utils/StorageUtil";

export default function Header() {
    const { t } = useTranslation();
    const u = getCookieByName("u");
    const a = getCookieByName("a");
    const dispatch = useDispatch()
    const {userName, avatar, authen, loginId} = useSelector((state : RootState) => state.authentication);
    const {approved, banner, uri, title} = useSelector((state : RootState) => state.enquete);

    useEffect(() => {
        document.title = `${title}`;
        if (u !== undefined && u.trim() !== "" && a !== undefined && a.trim() !== "") {
            axios.post(`${process.env.REACT_APP_API_ENDPOINT || ""}xmlrpc/json`, {
                "u": u,
                "a": a,
                "methodName": "ac.CheckAuth"
            }).then(res => {
                if (res.status !== 200 || res.data.success === false) {
                    getLoginPage();
                }else{
                    dispatch(setLoginId(u));
                    dispatch(setAuthen(true));
                    dispatch(setUserName(res.data?.userName_ja));
                    saveToLocalStorage('userName', res.data?.userName_ja);
                }
            }).catch(err => {
            })

            axios.get(`${process.env.REACT_APP_API_ENDPOINT || ""}GetPresence3?format=json&a=${a}&u=${u}`).then((res) => {
                dispatch(setAvatar(res.data?.participant?.imageUrl.replace("http", "https")));
            }).catch((err) => {
            })
        }

        if(approved) {
            if(!authen){
                getLoginPage();
            }
        }
    }, [a, approved, dispatch, u, authen, title]);

    const getLoginPage = () => {
        const host = window.location.host;
        if (host.indexOf('14.177.235.12') >= 0 || host.indexOf('172.16.10.224') >= 0 || host.indexOf('localhost') >= 0) {
            return `/login?continue=${window.location.href.replace('question' || 'confirm' || 'thank', 'top')}`
        }
        return `${process.env.REACT_APP_API_AUTH_PAGE || ""}?continue=${window.location.href.replace('question' || 'confirm' || 'thank', 'top')}`;
    };

    const logoutRedirectLoginPage = () => {
        dispatch(setAuthen(false));
        const logoutUrl = `${process.env.REACT_APP_LOGOUT_PAGE || ""}?continue=${encodeURIComponent(window.location.href.replace('question' || 'confirm' || 'thank', 'top'))}`;
        // remove all cookie
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        window.location.href = logoutUrl;
    }

    return (
        <>
            <header>
                <h1 className="acf-logo"><a href={`/top/${uri}`}>
                    {
                        banner && <img src={banner} height="34px" alt="ビジネス・ブレークスルー"/>
                    }</a>
                </h1>
                <ul className="nav nav-pills">
                    {
                        authen &&
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" id="dropdownMenuButton" href={"/"}  data-mdb-toggle="dropdown"
                               aria-expanded="false">
                                <img className="ac-user" alt={""} src={avatar}/>
                                <span>{userName}</span>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li><a className="dropdown-item" href="#/">ID: {loginId}</a></li>
                                <li><a className="dropdown-item" href={process.env.REACT_APP_OPERATION_MANUAL} target="_blank" rel="noreferrer">操作マニュアル</a></li>
                                <li><a className="dropdown-item" href={process.env.REACT_APP_CONTACT} target="_blank" rel="noreferrer">お問い合わせ</a></li>
                                <li><span className="dropdown-item logout" style={{cursor:"pointer"}} onClick={() => {logoutRedirectLoginPage()}}>ログアウト</span></li>
                            </ul>
                        </li>
                    }
                    {
                        !authen && <a href={getLoginPage()}>{t('login')}</a>
                    }
                </ul>
            </header>
        </>
    )};