import React from 'react';
import { useTranslation } from 'react-i18next';

interface AnswerGroupComponentProps {
    title: string,
    totalPoint: number,
    advice?: string,
    groupNo: number,
    numberOfQuestion: number
}

const AnswerGroupComponent: React.FC<AnswerGroupComponentProps> = (props: AnswerGroupComponentProps) => {
    const { t } = useTranslation();
    const averagePoint = Math.round(props.totalPoint / props.numberOfQuestion * 10) / 10;
    return (
        <>
            <div className="acf-sect">
                <h4 className="acf-sect-header">
                    <p className="acf-sect-header-ttl"><span dangerouslySetInnerHTML={{ __html: props.title || '' }}/>（{t('group')}{props.groupNo}）</p>
                    <div className="acf-sect-header-point">
                        <p className="acf-sect-header-point-total">{t('total-score')}：<span>{props.totalPoint}</span>{t('points')}</p>
                        <p className="acf-sect-header-point-average">{t('average-score')}：<span>{averagePoint}</span>{t('points')}<span
                            className="acf-sect-header-point-number">（{t('number-of-question')}：{props.numberOfQuestion}）</span></p>
                    </div>
                </h4>
                <div className="progress">
                    <div className="progress-bar bg-ac-blue" role="progressbar" style={{width: Math.round(averagePoint * 10) + '%',
                        alignItems: averagePoint !== 0 ? 'center' : 'start'}} aria-valuenow={averagePoint} aria-valuemin={0} aria-valuemax={100}>
                        <div style={{position: "absolute"}}>{averagePoint}{t('points')}</div>
                    </div>
                </div>
                <div className="progress-point">
                    <span className="text-start">0{t('points')}</span>
                    <span className="text-center">5{t('points')}</span>
                    <span className="text-end">10{t('points')}</span>
                </div>
                <p className="acf-score-txt"><span dangerouslySetInnerHTML={{ __html: props.advice || '' }} /></p>
            </div>
        </>
    );
};

export default AnswerGroupComponent;