import {useLocation} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import PageNotFound from "../error/notFound/pageNotFound";
import AnswerTextComponent from "../advice/answerText";
import AnswerGroupComponent from "../advice/answerGroup";
import {useTranslation} from "react-i18next";
import axios from "axios";

interface AdviceResponse {
    title: string,
    questions?: question[],
    groups: group[],
    feedback?: string
}

interface question {
    title: string,
    answer: string
}

interface group {
    groupTitle: string,
    totalPoint: number,
    totalQuestion: number,
    advice: string
}

export default function Advice() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uri = queryParams.get('uri');
    const id = queryParams.get('id');
    const [response , setResponse] = useState<AdviceResponse|null>();
    const { t } = useTranslation();
    const [notExist, setNotExist] = useState(false);
    let questionNo = 0;
    let groupNo = 0;
    useEffect(() => {
        if (uri !== null && id !== null) {
            axios.get(`${process.env.REACT_APP_API_ENDPOINT || ""}api/v1/advices?uri=${uri}&id=${id}`)
                .then(res => {
                    if(res.data !== '')
                        setResponse(res.data);
                    else
                        setNotExist(true);
                })
        } else {
            setNotExist(true)
        }
    }, [uri, id]);
    return (
        <>
            { response ?
                <div className="container acf-wrap">
                    <h2><span dangerouslySetInnerHTML={{ __html: response.title || '' }}/></h2>
                    <p className="acf-comp-ttl">{t('title-score-and-advice')}</p>
                    <div className="acf-score">
                        {
                            response.questions?.map(item => {
                                questionNo++;
                                return <AnswerTextComponent title={item.title} questionNo={questionNo} required={true} text={item.answer}/>
                            })
                        }
                        {
                            response.groups?.map(item => {
                                groupNo++;
                                return <AnswerGroupComponent
                                    numberOfQuestion={item.totalQuestion}
                                    title={item.groupTitle}
                                    groupNo={groupNo}
                                    totalPoint={item.totalPoint}
                                    advice={item.advice}
                                    key={`group${groupNo}`}/>
                            })
                        }
                    </div>
                    {
                        response.feedback &&
                            <div className="feedback-gpt">
                                <div className="acf-sect">
                                    <h4 className="acf-sect-header"><p className="feedback-header">{t('feedback-chatgpt')}</p></h4>
                                    <pre style={{whiteSpace: 'break-spaces'}} dangerouslySetInnerHTML={{ __html: response.feedback || '' }} />
                                </div>
                            </div>
                    }
                </div> : <></>
            }
            {
                notExist ? <PageNotFound/> : <></>
            }

        </>
    );
}