import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AuthenticationSliceState {
    loginId: string
    userName_en: string
    success: boolean
    userName: string
    avatar: string
    authen: boolean
    recaptchaPass: boolean
    email: string
};


const initialState: AuthenticationSliceState = {
    loginId: "",
    userName_en: "",
    success: false,
    userName: "",
    avatar: "",
    authen: false,
    recaptchaPass: false,
    email: ""
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setLoginId: (state, action: PayloadAction<string>) => {
            state.loginId = action.payload;
        },
        setUserName: (state, action: PayloadAction<string>) => {
            state.userName = action.payload;
        },
        setAvatar: (state, action: PayloadAction<string>) => {
            state.avatar = action.payload;
        },
        setAuthen: (state, action: PayloadAction<boolean>) => {
            state.authen = action.payload;
        },
        setReCAPTCHA: (state, action: PayloadAction<boolean>) => {
            state.recaptchaPass = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
    }
});
export const {setLoginId, setUserName, setAvatar, setAuthen, setReCAPTCHA, setEmail} = authenticationSlice.actions;
export default authenticationSlice.reducer;