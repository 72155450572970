import { useTranslation } from "react-i18next";

function PageNotFound () {

    const { t } = useTranslation();

    return (
        <div style={{width:"100%", paddingRight:"15px", paddingLeft:"15px", marginRight:"auto", marginLeft:"auto", padding:"100px 0 100px", textAlign:"center"}}>
            <h1 id="titlePage" className="h3 mb-2 text-gray-800" style={{fontSize:"2.5rem"}}>{t('page-404-title')}</h1>
            <p className="mt-4 mb-1">{t('page-404-content')}</p>
            <p>Error 404.</p>
            <img src="/assets/images/404-error.svg" alt="このページはご利用いただけません" style={{width:"507px", margin:"60px auto 0", display:"block", maxWidth:"100%", height:"auto", verticalAlign:"middle"}}/>
        </div>
    );
}
export default PageNotFound;
