import {configureStore} from '@reduxjs/toolkit'
import authenticationReducer from "./services/authentication/authenticationSlice";
import enqueteReducer from "./services/enquete/enqueteSlice";

export function makeStore() {
    return configureStore({
        reducer: {
            authentication: authenticationReducer,
            enquete: enqueteReducer
        },
    })
}

const store = makeStore()
export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
