import {useLocation} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import PageNotFound from "../error/notFound/pageNotFound";
import Question from "../confirm/question";
import axios from "axios";
import Feedback from "../form/feedback";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {setTitle} from "../../services/enquete/enqueteSlice";
import {useDispatch} from "react-redux";

interface AnswerResultResponse {
    enquete: enqueteInfo,
    result: item[],
    feedback: feedback[],
    messageForSubmit?: string
}

interface feedback{
    id: number,
    answerId: number,
    createdBy: string,
    feedback: string,
}
interface enqueteInfo {
    title: string,
    autoSubmitChatGpt: boolean
}

interface item {
    group_questions: item[],
    id: string,
    answer_type: number,
    //title question
    question: string,
    attach: attachment[],
    answer: answer[],
    required: boolean,
    choices?: choice[]
}

interface choice {
    //title
    choice: string
    point: number
}

interface answer {
    question_id: string,
    //title
    answer_type: number
    text?: string,
    choice?: string[],
    selected_other: boolean,
    file?: string,
    uri?: string
}

interface attachment {
    file_path: string,
    attach_id: string
    file_name: string,
    attach_file_type: number,
    attach_view_type: number
}
export default function AnswerResult() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uri = queryParams.get('uri');
    const id = queryParams.get('id');
    const isAnswerResult = true;
    const [response , setResponse] = useState<AnswerResultResponse|null>();
    const [notExist, setNotExist] = useState(false);
    const {authen} = useSelector((state : RootState) => state.authentication);
    let questionNo = 0;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        dispatch(setTitle(response?.enquete?.title ? `回答結果 | ${response?.enquete?.title}` : "AirCampus Form"));
    }, [dispatch, response])
    useEffect(() => {
        if (uri !== null && id !== null) {
            axios.get(`${process.env.REACT_APP_API_ENDPOINT || ""}api/v1/answerResult?uri=${uri}&id=${id}`)
                .then(res => {
                    if(res.data !== ''){
                        setResponse(res.data);
                    } else
                        setNotExist(true);
                })
        } else {
            setNotExist(true)
        }
    }, [uri, id, dispatch]);

    function renderQuestion(item: item, questionNo: number) {
        switch (item.answer_type) {
            case 35:
            case 10:
            case 33:
            case 30:
            case 40:
            case 70:
            case 50:
            case 60:
                return <Question key={item.answer?.[0]?.question_id} id={item.answer?.[0]?.question_id}
                                 attachment={item.attach}
                                 uri=''
                                 data={item}
                                 required={false}
                                 title={item.question}
                                 questionNo={questionNo}
                                 type={item.answer_type}
                                 isAnswerResult={isAnswerResult}/>;
        }
    };

    return (
        <>
            {
                response ? <div className="container acf-wrap">
                    <h2>{response.enquete?.title}</h2>
                    {response.feedback && <Feedback authen={authen} message={response.messageForSubmit} content={response.feedback}></Feedback>}
                    <div>
                        <h2>{t('answer-result')}</h2>
                        <section className="acf-sect">
                            {
                                response?.result?.map(item => {
                                    questionNo++;
                                    return [renderQuestion(item, questionNo)];
                                })
                            }
                        </section>
                    </div>
                </div> : <></>
            }
            {
                notExist ? <PageNotFound/> : <></>
            }
        </>
    );
}