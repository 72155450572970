import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './translations/translations_en.json';
import jaTranslation from './translations/translations_ja.json';
import { initReactI18next } from 'react-i18next';

const DETECTION_OPTIONS = {
    order: ['navigator']
};

i18n.use(LanguageDetector)
    .use(initReactI18next).init({
    detection: DETECTION_OPTIONS,
    resources: {
        en: {
            translation: enTranslation
        },
        ja: {
            translation: jaTranslation
        }
    },
    fallbackLng: 'ja',
    interpolation: {
        escapeValue: false
    }
});

export default i18n;