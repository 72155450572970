import React from 'react';
import Answer from "./answer";
import {useNavigate} from "react-router-dom";
import ViewFileComponent from '../form/view/viewfile';
import { useTranslation } from 'react-i18next';

interface QuestionComponentProps {
    uri: string | undefined,
    id?: string,
    title?: string,
    questionNo?: number,
    type: number,
    attachment?: attachment[],
    required: boolean,
    data?: item,
    isAnswerResult: boolean
}

interface item {
    group_questions: item[],
    id: string,
    answer_type: number,
    //title question
    question: string,
    attach: attachment[],
    answer: answer[],
    required: boolean,
    choices?: choice[]
}

interface choice {
    //title
    choice: string
    point: number
}

interface answer {
    question_id: string,
    //title
    answer_type: number
    text?: string,
    choice?: string[],
    selected_other: boolean,
    file?: string,
    uri?: string
}

interface attachment {
    file_path: string,
    attach_id: string
    file_name: string,
    attach_file_type: number,
    attach_view_type: number
}

const QuestionComponent: React.FC<QuestionComponentProps> = (props: QuestionComponentProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
            <div className="acf-sect-inner" id={props.id} style={props.type === 45 ? {border:"none"} : {}}>
                <h3 className="acf-ques">
                    {props.type !== 36 && <span className="acf-ques-num">{props.questionNo}</span>}
                    {
                        props.title && <span dangerouslySetInnerHTML={{ __html: props.title || '' }}/>
                    }
                    {props.required ? <span className="acf-ques-req" style={{ width: "auto" }}>{t('required')}</span> : <></>}
                    <br/>
                    {!props.isAnswerResult && <button className="acf-re button-confirm" onClick={() => {navigate(`/question/${props.uri}/${props.id}`);}}>{t('return-confirm')}</button>}</h3>
                <div>
                    {
                        props.attachment?.map(item => {
                            return <ViewFileComponent type={item.attach_file_type}
                                               fileName={item.file_name}
                                               filePath={item.file_path}
                                               id={item.attach_id}
                                               attachViewType={item.attach_view_type}
                                               />
                        })
                    }
                </div>
                <Answer data={props.data?.answer?.[0]}/>
            </div>
    );
};

export default QuestionComponent;