import React, {useEffect, useState} from 'react';
import {getCookieByName} from "../../utils/CookieUtil";
import Axios from "axios";
import Loading from "./loading/loading";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface FeedbackComponentProps {
    content: feedback[],
    message: string | undefined
    authen: boolean
}

interface feedback{
    id: number,
    answerId: number,
    createdBy: string,
    feedback: string,
}

const FeedbackComponent: React.FC<FeedbackComponentProps> = (props: FeedbackComponentProps) => {
    const [feedback, setFeedback] = useState(props.content[0]);
    const [index, setIndex] = useState(props.content?.length - 1);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const u = getCookieByName("u") || 'A9900492';
    const a = getCookieByName("a") || 'a81982f250ca4691eb2308e1d4f95c1f9accfcec';
    const id = queryParams.get('id');
    const [isLoading, setIsLoading] = useState(false);
    function submitAgain() {
        if(u !== '' && a !== '' && id !== null){
            setIsLoading(true);
            Axios.post(`${process.env.REACT_APP_API_ENDPOINT || ""}SubmitSaveFeedback?u=${u}&a=${a}`, {
                "answerId": id,
                "message": props.message,
            }).then(res => {
                props.content.push({
                    id: res.data.id,
                    answerId: 0,
                    createdBy: '',
                    feedback: res.data.newMessage,
                })
                setFeedback(props.content[props.content.length - 1])
                setIndex(props.content.length - 1)
            }).catch(err => {
            }).finally( () => {
                setIsLoading(false);
            })

        }else {
            alert("sai gi do")
        }
    }
    useEffect(() =>{
        setFeedback(props.content[index])
    }, [index, props.content])

    const { t } = useTranslation();
    return props.content ? <div>
        <div style={{display:"flex"}}>
            <h2>{t('feedback-title')}</h2>
            {
                props.content && props.content.length > 0 ?
                    <div style={{background: 'white', height: '40px', margin: '30px 0 0 15px'}}>
                        <div style={{margin: '5px'}}>
                            <button id="prev" style={{border: 'none', background: 'white'}} disabled={index === 0} onClick={() => {setIndex(index - 1)}}>
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                                </svg>
                            </button>
                            <span  style={{padding: '0 5px'}}>{index + 1}/{props.content.length}</span>
                            <button style={{border: 'none', background: 'white'}} disabled={index === props.content.length - 1} onClick={() => {setIndex(index + 1)}} id="next" >
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                </svg>
                            </button>
                        </div>
                    </div> : <></>
            }
            {
                props.authen && <button className={"button-link"} style={{margin:'36px 0 0 auto'}} onClick={submitAgain}>{t('generate-feedback')}</button>
            }
            {isLoading && <Loading/>}
        </div>
        {   props.content && props.content.length > 0 &&
            <div className="acf-sect">
                <section>
                    <pre style={{whiteSpace: 'break-spaces'}} dangerouslySetInnerHTML={{ __html: feedback?.feedback || '' }} />
                </section>
            </div>
        }
        </div>: <></>
};
export default FeedbackComponent;